var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-card',[_c('validation-observer',{ref:"selectForm"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('div',{staticClass:"full-width d-flex justify-content-center mb-2"},[(!_vm.userData.avatarUrl || _vm.userData.avatarUrl === '')?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$refs.avatar.$refs.input.click()}}},[_c('b-avatar',{attrs:{"size":"100px","text":_vm.avatarText(_vm.userData.firstname),"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.userData.role)))}})],1):_vm._e(),(_vm.userData.avatarUrl && _vm.userData.avatarUrl !== '')?_c('b-img',{staticClass:"cursor-pointer rounded-full",attrs:{"src":_vm.userData.avatarUrl,"width":"100px","height":"100px"},on:{"click":function($event){return _vm.$refs.avatar.$refs.input.click()}}}):_vm._e(),_c('b-form-file',{ref:"avatar",staticClass:"hidden",on:{"input":_vm.handleUploadAvatar},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}})],1)]),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-status"}},[_vm._v("Status "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":true,"state":errors.length > 0 ? false:null,"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"account-status"},model:{value:(_vm.userData.status),callback:function ($$v) {_vm.$set(_vm.userData, "status", $$v)},expression:"userData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-role"}},[_vm._v("Role "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"state":errors.length > 0 ? false:null,"options":_vm.roleOptions,"reduce":function (val) { return val.value; },"clearable":false,"disabled":true,"input-id":"account-role"},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{staticClass:"mt-1 full-width",attrs:{"variant":"danger"},on:{"click":function () { return _vm.makeResetPasswordToast(_vm.userData.id, _vm.userData.email); }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"RotateCcwIcon","size":"16"}}),_c('span',[_vm._v("Reset Password")])],1)],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-card',[_c('validation-observer',{ref:"inputForm"},[_c('b-form',[_c('h4',[_vm._v("Employee Information")]),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-empId"}},[_vm._v("Employment ID")]),_c('b-form-input',{attrs:{"id":"account-empId","placeholder":"Employment ID","name":"empId"},model:{value:(_vm.userData.id),callback:function ($$v) {_vm.$set(_vm.userData, "id", $$v)},expression:"userData.id"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-firstname"}},[_vm._v("First name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-firstname","placeholder":"First name","name":"firstname"},model:{value:(_vm.userData.firstname),callback:function ($$v) {_vm.$set(_vm.userData, "firstname", $$v)},expression:"userData.firstname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-lastname"}},[_vm._v("Last name "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-lastname","placeholder":"Last name","name":"lastname"},model:{value:(_vm.userData.lastname),callback:function ($$v) {_vm.$set(_vm.userData, "lastname", $$v)},expression:"userData.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-company"}},[_vm._v("Company")]),_c('b-form-input',{attrs:{"id":"account-company","placeholder":"e.g. AKARA, VASO Restuarant","name":"company"},model:{value:(_vm.userData.company),callback:function ($$v) {_vm.$set(_vm.userData, "company", $$v)},expression:"userData.company"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-team"}},[_vm._v("Department or team")]),_c('b-form-input',{attrs:{"id":"account-team","placeholder":"e.g. Marketing, Content Creator","name":"team"},model:{value:(_vm.userData.team),callback:function ($$v) {_vm.$set(_vm.userData, "team", $$v)},expression:"userData.team"}})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-about"}},[_vm._v("About me")]),_c('b-form-textarea',{attrs:{"id":"account-about","placeholder":"Describe about you to your team mate.","rows":"3"},model:{value:(_vm.userData.about),callback:function ($$v) {_vm.$set(_vm.userData, "about", $$v)},expression:"userData.about"}})],1)],1)],1),_c('h4',{staticClass:"mt-1"},[_vm._v("Contact Information")]),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-email"}},[_vm._v("Email "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"email address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":true,"state":errors.length > 0 ? false:null,"id":"account-email","placeholder":"Email","name":"email"},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-phone"}},[_vm._v("Mobile phone "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"mobile phone","rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"id":"account-phone","placeholder":"Mobile phone","name":"phone"},model:{value:(_vm.userData.tel),callback:function ($$v) {_vm.$set(_vm.userData, "tel", $$v)},expression:"userData.tel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-company-email"}},[_vm._v("Company email")]),_c('b-form-input',{attrs:{"id":"account-company-email","placeholder":"e.g. admin@akarahospitality.com","name":"company-email"},model:{value:(_vm.userData.companyEmail),callback:function ($$v) {_vm.$set(_vm.userData, "companyEmail", $$v)},expression:"userData.companyEmail"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"account-office-phone"}},[_vm._v("Office desk telephone")]),_c('b-form-input',{attrs:{"id":"account-office-phone","placeholder":"Office desk telephone","name":"office-phone"},model:{value:(_vm.userData.officePhone),callback:function ($$v) {_vm.$set(_vm.userData, "officePhone", $$v)},expression:"userData.officePhone"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"fixed-footer justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary","to":{ name: 'user-list'}}},[_c('span',[_vm._v("Cancel")])]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success"},on:{"click":_vm.confirmUpdate}},[_c('span',[_vm._v("Update")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }